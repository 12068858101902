import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Contact = () => {

  function sendMail() {
    trackCustomEvent({
      category: "Contact Footer",
      action: "Email Clicked",
      label: "Contact email clicked"
    })
		window.location.href = "mailto:info@viragmajor.hu";
  }
  
  function callMobile() {
    trackCustomEvent({
      category: "Contact Footer",
      action: "Mobile Clicked",
      label: "Contact phone number clicked"
    })
		window.location.href = "tel:+36209419577";
  }
  
  return (
    <section id="contact">
      <div className="container">
        <div className="wrapper">
          <div className="head">
            <h1>Miben segíthetünk?</h1>
            <p>Amennyiben termékpalettánk felkeltette az érdeklődését vagy kérdése merült fel termékeinkkel és szolgáltatásunkkal kapcsolatban kérjük, vegye fel velünk a kapcsolatot.</p>
          </div>

          <ul className="contacts">
            <li>
              <h4>E-mail</h4>
              <h2
                className="event"
                onClick={event => sendMail()}
              >
                info@viragmajor.hu
              </h2>
            </li>
            <li>
              <h4>Telefon</h4>
              <h2
                className="event"
                onClick={event => callMobile()}
              >
                +36 20 941 9577
              </h2>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Contact