import React, { useState } from "react"
import { Link } from "gatsby"

const ProductCard = ({ item, maxColor }) => {
  const [activeColor, setActiveColor] = useState(item.node.data.featured_variant - 1);

  return (
    <div className="product-card">
              
      <Link
        to={`/product/${item.node.uid}`}
        state={{ activeVariant: activeColor }}
      >
        <div
          className="image"
          // style={{transform: 'scale(' + item.node.data.phisical_scale + ')'}}
        >
          {item.node.data.variant.slice(0, maxColor).map((variant, i) => (
            <img
              key={i}
              src={variant.variant_image.thumbnails.thumbnail.url}
              alt={variant.variant_name.text}
              className={activeColor === i ? 'active' : ''}
            />
          ))}
        </div>

        <div className="info">
          <h4>{item.node.data.name.text}</h4>
          <small>{item.node.data.variant.length} változat</small>
        </div>
      </Link>

      <ul className="colors">
        {item.node.data.variant.slice(0, maxColor).map((variant, i) => (
          <li
            key={i}
            alt={variant.variant_name.text}
            onClick={event => setActiveColor(i)}
            className={activeColor === i ? 'active' : ''}
          >
            <span style={{backgroundColor: variant.variant_color}}></span>
          </li>
        ))}
        {(() => {
          if (item.node.data.variant.length > maxColor) {
            return (
              <li className="more">
                <Link to={`/product/${item.node.uid}`}>
                  + {item.node.data.variant.length-maxColor}
                </Link>
              </li>
            )
          }
        })()}
      </ul>

    </div>
  )
};

export default ProductCard