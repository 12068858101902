import React from "react"
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

const Foot = ({ edges }) => {
  const menu = edges;
  const menuSorted = menu.sort(function(a, b){
    return a.node.data.sort - b.node.data.sort;
  });

  return (
    <footer>
      <div className="container">
        <div className="wrapper">
          <small>Copyright © 2020 Minden jog fenntartva</small>
          <ul>
            {menuSorted.map((item, i) => (
              <li key={i}>
                <Link to={"/" + item.node.uid} >
                  <small>{item.node.data.title.text}</small>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  )
}

Foot.propTypes = {
  edges: PropTypes.array,
};

const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicPage {
          edges {
            node {
              id
              data {
                title {
                  text
                }
                sort
              }
              uid
            }
          }
        }
      }
    `}
    render={data => (
      <Foot {...data.allPrismicPage} {...props} />
    )}
  />
);

export default Footer;
